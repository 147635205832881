type Props = {
  className?: string;
};

const SaladLogo = ({ className }: Props) => {
  return (
    <svg
      className={className}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="327.000000pt"
      height="231.000000pt"
      viewBox="0 0 327.000000 231.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,231.000000) scale(0.100000,-0.100000)"
        fill="white"
        stroke="none"
      >
        <path
          d="M1537 2194 c-124 -30 -233 -105 -302 -210 -28 -41 -41 -54 -53 -49
   -95 40 -235 46 -334 15 -200 -61 -348 -255 -348 -453 0 -61 13 -85 56 -103 71
   -30 134 28 134 123 0 51 39 131 85 178 107 107 284 119 402 29 76 -58 149 -31
   172 63 36 146 177 244 330 230 113 -10 225 -96 256 -197 23 -75 55 -110 103
   -110 9 0 50 16 89 37 64 32 82 36 145 36 163 0 287 -112 307 -279 6 -50 14
   -71 33 -91 58 -58 158 -11 158 75 -1 210 -173 424 -380 471 -67 16 -189 13
   -250 -5 -38 -11 -55 -13 -58 -5 -9 26 -85 118 -125 150 -82 66 -217 112 -321
   110 -23 0 -67 -7 -99 -15z"
        />
        <path
          d="M564 1173 c22 -319 178 -589 436 -755 41 -26 94 -55 117 -64 l41 -16
   4 -95 c3 -90 4 -96 31 -119 l29 -25 420 3 420 3 19 24 c15 19 19 40 20 115 l0
   91 87 42 c304 147 512 478 512 814 l0 79 -1071 0 -1072 0 7 -97z"
        />
      </g>
    </svg>
  );
};

export default SaladLogo;
