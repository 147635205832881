import React, { RefObject } from "react";

import {
  RestaurantClosingRulesType,
  RestaurantItemType,
  RestaurantOpeningHoursType,
  RestaurantOptionsType,
} from "../../../api/types/restaurant.type";
import { useAppDispatch } from "../../../redux/hooks";
import {
  setRestaurantInfo,
  setRestaurantTime,
} from "../../../redux/slices/restaurantSlice";
import { useMap } from "../../../store/map.context";
import { sqDist } from "../../../utils/location";
import classes from "./location.module.scss";

interface Props {
  restaurants: RestaurantItemType[];
  restaurantOptions: RestaurantOptionsType;
  inputRef: RefObject<HTMLTextAreaElement>;
  outsideRef?: RefObject<HTMLDivElement>;
  setOpenLocations: React.Dispatch<React.SetStateAction<boolean>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setTarget: React.Dispatch<React.SetStateAction<boolean>>;
}

const Locations = ({
  restaurants,
  restaurantOptions,
  inputRef,
  outsideRef,
  setOpenLocations,
  setDisabled,
  setTarget,
}: Props) => {
  const { setMap } = useMap();
  const dispatch = useAppDispatch();

  const setRestaurant = (
    id: number,
    name: string,
    address: string,
    openingHours: RestaurantOpeningHoursType[],
    closingRules: RestaurantClosingRulesType[]
  ) => {
    dispatch(
      setRestaurantInfo({ id, name, address, openingHours, closingRules })
    );
    dispatch(setRestaurantTime(""));
    setDisabled(false);
  };

  const getName = (value: string) => {
    return value.substring(10);
  };

  const selectRestaurant = (location: RestaurantItemType) => {
    if (inputRef.current) {
      inputRef.current.value = location.addresse + " " + getName(location.name);
    }
    setRestaurant(
      location.id,
      location.name,
      location.addresse as string,
      location.opening_hours,
      location.closing_rules
    );
    setMap({
      id: location.id,
      lat: location.latitude as number,
      lng: location.longitude as number,
    });
    setOpenLocations(false);
  };

  const handleSortedRestaurants = () => {
    const { defaultRestaurants, localFilteredRestaurants, position } =
      restaurantOptions;

    if (defaultRestaurants) {
      return restaurants.sort((a, b) => a.name.localeCompare(b.name));
    }
    if (localFilteredRestaurants.length === 0) {
      return restaurants.sort((a, b) => {
        return (
          sqDist(position, a.latitude ?? 0, a.longitude ?? 0) -
          sqDist(position, b.latitude ?? 0, b.longitude ?? 0)
        );
      });
    }
    const tmp2 = restaurants.filter(
      (item) =>
        !localFilteredRestaurants.find((item2) => item2.name === item.name)
    );
    return [...localFilteredRestaurants, ...tmp2];
  };

  const sortedRestaurants = handleSortedRestaurants().filter(
    (restaurant, index, array) => {
      return (
        array.findIndex(
          (t) =>
            t.name === restaurant.name && t.addresse === restaurant.addresse
        ) === index
      );
    }
  );

  return (
    <div className={classes.list} tabIndex={0} ref={outsideRef}>
      {sortedRestaurants.map((location, i) => (
        <p
          key={i}
          className={classes.item}
          onClick={() => {
            selectRestaurant(location);
            setTarget(false);
          }}
        >
          {location.name.replace("Eat Salad ", "")}
          {", "}
          {location.addresse}
        </p>
      ))}
    </div>
  );
};

export default Locations;
