import { useState } from "react";
import { RxCaretDown } from "react-icons/rx";
import { PersistGate } from "redux-persist/integration/react";

import { translationsContent } from "../../../../localization/translations";
import { useAppSelector } from "../../../../redux/hooks";
import { selectRestaurant } from "../../../../redux/slices/restaurantSlice";
import { persistor } from "../../../../redux/store";
import { useLanguage } from "../../../../store/lang.context";
import { useTakeawaySelector } from "../../../../store/takeawaySelector.context";
import { translate } from "../../../../utils/translate";
import classes from "./restaurantHoursList.module.scss";

interface Props {
  standalone: boolean;
}

const RestaurantHoursList = ({ standalone }: Props) => {
  const [selectorOpen, setSelectorOpen] = useState(false);
  useState(false);

  const { language } = useLanguage();

  const toggleSelectorOpen = () => setSelectorOpen(!selectorOpen);

  const { name, address, date, time } = useAppSelector(selectRestaurant);

  const { setTakeawaySelector } = useTakeawaySelector();

  const setRestaurantAndTimeSelectorIsOpen = () => {
    setTakeawaySelector((prev) => ({ ...prev, isTakeawayModalOpen: true }));
  };
  return (
    <PersistGate persistor={persistor} loading={<>Loading...</>}>
      <div
        className={`${classes.container} ${standalone && classes.standalone} ${
          selectorOpen && classes.fullHeight
        } ${
          (name === "" || time === "") && !selectorOpen && classes.adaptHeight
        }`}
      >
        <div className={classes.selector} onClick={toggleSelectorOpen}>
          <div>
            <div className={classes.title}>
              {translate(translationsContent, "orderNow", language)}
            </div>
            <div className={classes.subtitle}>{address}</div>
            <div style={{ fontSize: "14px" }}>
              {date && time
                ? date === "dynamic"
                  ? `${translate(
                      translationsContent,
                      "confirmationContent4",
                      language
                    )}`
                  : `${translate(
                      translationsContent,
                      "confirmationContent5",
                      language
                    )}`
                : ""}
              {time && (
                <>
                  {" "}
                  a <b>{time.split(" ")[0]}</b>
                </>
              )}
            </div>
          </div>
          <div
            className={`${classes.icon} ${selectorOpen && classes.upsideDown}`}
          >
            <RxCaretDown />
          </div>
        </div>
        <div className={classes.title} style={{ margin: "25px 0" }}>
          {translate(translationsContent, "restHoursList1", language)}
        </div>
        <input
          type="text"
          value={name}
          readOnly
          onClick={setRestaurantAndTimeSelectorIsOpen}
        />
        <div className={classes.title} style={{ margin: "25px 0" }}>
          {translate(translationsContent, "restHoursList2", language)}
        </div>
        <input
          type="text"
          value={time}
          readOnly
          onClick={setRestaurantAndTimeSelectorIsOpen}
        />
        <div
          className={classes.subtitle}
          style={{ textAlign: "center", marginTop: "15px" }}
        >
          {translate(translationsContent, "restHoursList3", language)}
        </div>
      </div>
    </PersistGate>
  );
};

export default RestaurantHoursList;
