import { AxiosResponse } from "axios";

import { sendRequest } from "../request/axios";
import { RestaurantItemType } from "../types/restaurant.type";

export async function getRestaurantById(id: number) {
  try {
    const res = await sendRequest<never, AxiosResponse<RestaurantItemType>>(
      "get",
      `/restaurants/${id}/`
    );
    return { data: res.data, success: true };
  } catch (error: unknown) {
    return {
      success: false,
    };
  }
}
