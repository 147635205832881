import { FormEvent } from "react";

import classes from "./subtractButton.module.scss";

interface Props {
  onClick?: (event: FormEvent) => unknown;
  alternativeLook?: boolean;
}

const SubtractButton = ({ onClick, alternativeLook }: Props) => {
  return (
    <button
      className={`${classes.button} ${alternativeLook && classes.altLook}`}
      onClick={onClick}
    >
      -
    </button>
  );
};

export default SubtractButton;
