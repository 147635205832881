import { Position } from "google-map-react";
import moment from "moment";

import { RestaurantOpeningHoursType } from "../api/types/restaurant.type";

export const sqDist = (position: Position, lat: number, lng: number) => {
  return (position?.lat - lat) ** 2 + (position.lng - lng) ** 2;
};

export const filterOpeningHoursOfRestaurantFromInitialTime = (
  initiaTimeOptions: string[],
  item: RestaurantOpeningHoursType
) =>
  initiaTimeOptions.filter((option) => {
    return (
      +item.from_hour.substring(0, 2) * 100 + +item.from_hour.substring(3, 5) <=
        +option.substring(0, 2) * 100 + +option.substring(3, 5) &&
      +option.substring(0, 2) * 100 + +option.substring(3, 5) <
        +item.to_hour.substring(0, 2) * 100 + +item.to_hour.substring(3, 5)
    );
  });

export const excludeClosingHoursOfRestaurant = (
  tmp1: string[],
  closingHours: string[]
) =>
  tmp1.filter((option) => {
    const result = !closingHours.some((item) => {
      return (
        (+option.split(" - ")[1].substring(0, 2) * 100 +
          +option.split(" - ")[1].substring(3, 5) >=
          +item.split(" - ")[0].substring(0, 2) * 100 +
            +item.split(" - ")[0].substring(3, 5) &&
          +option.split(" - ")[1].substring(0, 2) * 100 +
            +option.split(" - ")[1].substring(3, 5) <=
            +item.split(" - ")[1].substring(0, 2) * 100 +
              +item.split(" - ")[1].substring(3, 5)) ||
        (+option.split(" - ")[0].substring(0, 2) * 100 +
          +option.split(" - ")[0].substring(3, 5) <=
          +item.split(" - ")[1].substring(0, 2) * 100 +
            +item.split(" - ")[1].substring(3, 5) &&
          +option.split(" - ")[0].substring(0, 2) * 100 +
            +option.split(" - ")[0].substring(3, 5) >=
            +item.split(" - ")[0].substring(0, 2) * 100 +
              +item.split(" - ")[0].substring(3, 5))
      );
    });
    return result;
  });

const generateTimeOptions = () => {
  const initiaTimeOptions = [];
  const initialTime = moment(new Date(2000, 1, 1, 10, 0, 0));
  const finalTime = moment(new Date(2000, 1, 1, 22, 30, 0));
  while (initialTime <= finalTime) {
    initiaTimeOptions.push(
      `${initialTime.format("HH")}:${initialTime.format("mm")} - ${initialTime
        .add(15, "minutes")
        .format("HH")}:${initialTime.format("mm")}`
    );
  }
  return initiaTimeOptions;
};

export const initiaTimeOptions = generateTimeOptions();
