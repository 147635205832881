import Image from "next/image";
import Link from "next/link";
import router from "next/router";
import { FormEvent, useState } from "react";
import { MdClose, MdOutlineStyle, MdPerson, MdStar } from "react-icons/md";

import { signOut } from "../../../../api/users/logout";
import { translationsContent } from "../../../../localization/translations";
import { useAppDispatch } from "../../../../redux/hooks";
import { clearCart } from "../../../../redux/slices/cartSlice";
import { resetRestaurantInfo } from "../../../../redux/slices/restaurantSlice";
import { useLanguage } from "../../../../store/lang.context";
import { useUser } from "../../../../store/user.context";
import imageLoader from "../../../../utils/loader";
import { translate } from "../../../../utils/translate";
import HeaderItem from "../../../layout/Header/NavBar/Item";
import SaladLogo from "../../svgs/SaladLogo";
import { OptionProps } from "../AccountDropdown";
import classes from "./accountDropdownMobile.module.scss";

const AccountDropdownMobile = () => {
  const { setUser } = useUser();
  const [opened, setOpened] = useState(false);

  const { language } = useLanguage();
  const dispatch = useAppDispatch();

  const SaladIcon = () => {
    return (
      <>
        <div className={classes.notActive}>
          <Image
            loader={imageLoader}
            src={"salad.svg"}
            alt={"Salad Icon"}
            width={20}
            height={20}
          />
        </div>
        <div className={classes.active}>
          <Image
            loader={imageLoader}
            src={"salad-green.svg"}
            alt={"Selected Salad Icon"}
            width={20}
            height={20}
          />
        </div>
      </>
    );
  };

  const options: OptionProps[] = [
    {
      label: `${translate(
        translationsContent,
        "accountHeader1Label",
        language
      )}`,
      value: `${translate(translationsContent, "accountHeader1", language)}`,
      selected:
        router.pathname ==
        `${translate(translationsContent, "accountHeader1", language)}`,
      children: <MdPerson className={classes.icon} />,
    },
    {
      label: `${translate(
        translationsContent,
        "accountHeader2Label",
        language
      )}`,
      value: `${translate(translationsContent, "accountHeader2", language)}`,
      selected:
        router.pathname ==
        `${translate(translationsContent, "accountHeader2", language)}`,
      children: <MdOutlineStyle className={classes.icon} />,
    },
    {
      label: `${translate(
        translationsContent,
        "accountHeader4Label",
        language
      )}`,
      value: `${translate(translationsContent, "accountHeader4", language)}`,
      selected:
        router.pathname ==
        `${translate(translationsContent, "accountHeader4", language)}`,
      children: <MdStar className={classes.icon} />,
    },
    {
      label: `${translate(
        translationsContent,
        "accountHeader5Label",
        language
      )}`,
      value: `${translate(translationsContent, "accountHeader5", language)}`,
      selected:
        router.pathname ==
        `${translate(translationsContent, "accountHeader5", language)}`,
      children: <SaladLogo className={classes.icon2} />,
    },
    {
      label: `${translate(translationsContent, "disconnect", language)}`,
      value: "/",
      children: <></>,
      onClick: () => {
        setUser(undefined);
        signOut();
        dispatch(clearCart());
        dispatch(resetRestaurantInfo());
      },
    },
  ];

  const Option = ({
    children,
    value,
    header = false,
    label,
    selected,
    onClick,
  }: React.PropsWithChildren<OptionProps>) => {
    const handleClick = (e: FormEvent, path: string) => {
      e.preventDefault();

      if (onClick) onClick();
      router.push(path);
    };

    return (
      <Link href={""}>
        <a
          className={classes.link}
          onClick={(e) => {
            handleClick(e, value as string);
          }}
        >
          <div
            className={`${classes.button} ${selected ? classes.selected : ""}`}
          >
            <div className={classes.icon}>{children}</div>
            <div className={`${classes.label} ${header ? classes.header : ""}`}>
              {label}
            </div>
          </div>
        </a>
      </Link>
    );
  };

  return (
    <>
      <HeaderItem
        src="profile.svg"
        size={16}
        headerNavName="headerNav1"
        onClick={() => {
          setOpened(true);
        }}
      />
      <div className={classes.dropdown}>
        {opened ? (
          <>
            <div className={classes.header}>
              <h2>Mon compte</h2>
              <MdClose
                className={classes.close}
                onClick={() => {
                  setOpened(false);
                }}
              >
                x
              </MdClose>
            </div>
            <div
              className={classes.menu}
              onClick={() => {
                setOpened(false);
              }}
            >
              {!!options &&
                options.map((option, i) => {
                  return (
                    <div
                      key={`mobile-dropdown-${i}`}
                      className={classes.option}
                    >
                      <Option
                        value={option.value}
                        label={option.label}
                        selected={option.selected}
                        onClick={option.onClick}
                      >
                        {option.children}
                      </Option>
                    </div>
                  );
                })}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default AccountDropdownMobile;
