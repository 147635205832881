import { useMediaQuery } from "@mui/material";
import Image from "next/image";
import { PersistGate } from "redux-persist/integration/react";

import { translationsContent } from "../../../../../localization/translations";
import { useAppSelector } from "../../../../../redux/hooks";
import { selectCart } from "../../../../../redux/slices/cartSlice";
import { persistor } from "../../../../../redux/store";
import { useLanguage } from "../../../../../store/lang.context";
import imageLoader from "../../../../../utils/loader";
import { translate } from "../../../../../utils/translate";
import classes from "./headerItem.module.scss";

type HeaderNavName = "headerNav1" | "headerNav2" | "headerNav3";

interface Props {
  src: string;
  size: number;
  headerNavName: HeaderNavName;
  isIconComponent?: boolean;
  onClick: () => void;
}

const HeaderItem = ({
  headerNavName,
  onClick,
  src,
  size,
  isIconComponent = false,
  children,
}: React.PropsWithChildren<Props>) => {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const { language } = useLanguage();

  const cart = useAppSelector(selectCart);

  return (
    <>
      {headerNavName === "headerNav2" ? (
        <PersistGate persistor={persistor} loading={<>Loading...</>}>
          <div className={classes.item} onClick={onClick}>
            {isIconComponent ? (
              <span
                style={{
                  fontSize: `${size}px`,
                  lineHeight: `${size}px`,
                  textAlign: "center",
                  color: "black",
                  paddingTop: "4px",
                }}
              >
                {children}
              </span>
            ) : (
              <Image
                layout="fixed"
                loader={imageLoader}
                width={`${size}px`}
                height={`${size}px`}
                src={src}
                alt={headerNavName}
              />
            )}
            {(cart.items.length > 0 ||
              cart.formulaItems.length > 0 ||
              cart.customFormulaItems.length > 0 ||
              cart.signatures.length > 0 ||
              cart.customDessertItems.length > 0) && (
              <span className={classes.cartCounter}>
                {cart.items.length +
                  cart.formulaItems.length +
                  cart.customFormulaItems.length +
                  cart.signatures.length +
                  cart.customDessertItems.length}
              </span>
            )}
            {!isMobile && (
              <p>{translate(translationsContent, headerNavName, language)}</p>
            )}
          </div>
        </PersistGate>
      ) : (
        <div className={classes.item} onClick={onClick}>
          {isIconComponent ? (
            <span
              style={{
                fontSize: `${size}px`,
                lineHeight: `${size}px`,
                textAlign: "center",
                color: "black",
                paddingTop: "4px",
              }}
            >
              {children}
            </span>
          ) : (
            <Image
              layout="fixed"
              loader={imageLoader}
              width={`${size}px`}
              height={`${size}px`}
              src={src}
              alt={headerNavName}
            />
          )}
          {!isMobile && (
            <p>{translate(translationsContent, headerNavName, language)}</p>
          )}
        </div>
      )}
    </>
  );
};

export default HeaderItem;
