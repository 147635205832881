import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";
import { PersistGate } from "redux-persist/integration/react";

import { translationsContent } from "../../../localization/translations";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  addItemToCart,
  addSignatureSaladToCart,
  removeAlcoholicBeverages,
  removeItemFromCart,
  removeSignatureSaladFromCart,
  selectCart,
} from "../../../redux/slices/cartSlice";
import { persistor } from "../../../redux/store";
import { useLanguage } from "../../../store/lang.context";
import { useUser } from "../../../store/user.context";
import imageLoader from "../../../utils/loader";
import { translate } from "../../../utils/translate";
import Button from "../../ui/button/SimpleButton";
import RestaurantHoursList from "../../ui/list/RestaurantHoursList";
import CartItem from "./CartItem";
import classes from "./cartSidebar.module.scss";
import CustomSaladItemCard from "./CustomSaladItem";
import DessertItemCard from "./DessertItem";
import FormulaItemCard from "./FormulaItem";
import SignatureItem from "./SignatureItem";

interface Props {
  open: boolean;
  setSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CartSideBar = ({ open, setSideBarOpen }: Props) => {
  const cart = useAppSelector(selectCart);
  const dispatch = useAppDispatch();
  const router = useRouter();

  const { user } = useUser();

  const { language } = useLanguage();

  const regularCartItems = cart.items.filter((item) => {
    return !item.is_paid_with_eatfid_points;
  });

  const eatfidCartItems = cart.items.filter((item) => {
    return item.is_paid_with_eatfid_points;
  });

  const regularFormulaItems = cart.formulaItems.filter((formula) => {
    return !formula.is_paid_with_eatfid_points;
  });

  const eatfidFormulaItems = cart.formulaItems.filter((formula) => {
    return formula.is_paid_with_eatfid_points;
  });

  const handleOpenCart = () => {
    switch (language) {
      case "fr":
        router.push("/panier/");
        break;
      case "en":
        router.push("/en/cart/");
        break;
      case "es":
        router.push("/es/cesta/");
        break;
      case "nl":
        router.push("/nl/winkelmandje/");
    }
    setSideBarOpen(false);
  };

  return (
    <PersistGate persistor={persistor} loading={<>Loading...</>}>
      <div className={!open ? classes.sidebarOpen : classes.sidebarClose}>
        <div className={classes.innerContainer}>
          <div className={classes.cancel}>
            <Image
              layout="fixed"
              loader={imageLoader}
              width={"30px"}
              height={"30px"}
              src={"cancel.svg"}
              alt="cancel"
              onClick={() => {
                setSideBarOpen(false);
              }}
            />
          </div>
          <div className={classes.title}>
            <h1>Panier</h1>
          </div>
          <div className={classes.hoursList}>
            <RestaurantHoursList standalone={false} />
          </div>
          <div className={classes.list}>
            {cart.customFormulaItems.map((item, index) => (
              <CustomSaladItemCard key={index} item={item} id={index} />
            ))}
            {cart.customDessertItems.map((dessert, index) => (
              <DessertItemCard key={index} dessert={dessert} id={index} />
            ))}
            {regularFormulaItems.map((formula) => (
              <FormulaItemCard
                key={formula.formula.id}
                formula={formula}
                id={formula.orderId}
              />
            ))}
            {regularCartItems.map((item, index) => {
              const incrementItem = () =>
                dispatch(addItemToCart({ ingredient: item.ingredient }));
              const decrementItem = () => {
                dispatch(removeItemFromCart(item.ingredient));
                dispatch(removeAlcoholicBeverages());
              };
              return (
                <CartItem
                  key={index}
                  title={item.ingredient.title}
                  qty={item.quantity}
                  price={item.itemTotal}
                  onIncrement={incrementItem}
                  onDecrement={decrementItem}
                  isEatfid={item.is_paid_with_eatfid_points}
                />
              );
            })}

            {cart.signatures.map((signature, index) => {
              const incrementItem = () =>
                dispatch(
                  addSignatureSaladToCart({
                    ingredient: signature.ingredient,
                    freeSauce: signature.freeSauce,
                    asNew: false,
                  })
                );
              const decrementItem = () => {
                dispatch(
                  removeSignatureSaladFromCart({
                    ingredient: signature.ingredient,
                    freeSauce: signature.freeSauce,
                  })
                );
                dispatch(removeAlcoholicBeverages());
              };
              return (
                <SignatureItem
                  key={index}
                  title={signature.ingredient.title}
                  qty={signature.quantity}
                  price={signature.itemTotal}
                  freeSauce={signature.freeSauce}
                  onIncrement={incrementItem}
                  onDecrement={decrementItem}
                />
              );
            })}
            {(eatfidCartItems.length > 0 || eatfidFormulaItems.length > 0) && (
              <>
                <div className={classes.eatfidTitleContainer}>
                  <Image
                    loader={imageLoader}
                    src={"fidelity.png"}
                    alt={"La Fidélité Logo"}
                    width={120}
                    height={60}
                    objectFit="contain"
                  />
                </div>
                {eatfidFormulaItems.map((formula) => {
                  return (
                    <FormulaItemCard
                      key={formula.formula.id}
                      formula={formula}
                      id={formula.orderId}
                      isEatfid={formula.is_paid_with_eatfid_points}
                    />
                  );
                })}
                {eatfidCartItems.map((item, index) => {
                  const decrementItem = () => {
                    if (user) {
                      dispatch(removeItemFromCart(item.ingredient));
                    }
                  };
                  return (
                    <CartItem
                      key={index}
                      title={item.ingredient.title}
                      qty={item.quantity}
                      price={item.itemTotal}
                      onIncrement={() => {}}
                      onDecrement={decrementItem}
                      isEatfid={item.is_paid_with_eatfid_points}
                    />
                  );
                })}
              </>
            )}
          </div>
          <div className={classes.bottomContainer}>
            <div>
              <h3>TOTAL</h3>
              <span>{(Math.abs(cart.cartTotal) / 100).toFixed(2)} &euro;</span>
            </div>
            <Button
              label={`${translate(
                translationsContent,
                "paymentBtnLabel1",
                language
              )}`}
              disabled={
                cart.items.length === 0 &&
                cart.formulaItems.length === 0 &&
                cart.customFormulaItems.length === 0 &&
                cart.signatures.length === 0 &&
                cart.customDessertItems.length === 0
              }
              onClick={handleOpenCart}
            />
          </div>
        </div>
      </div>
    </PersistGate>
  );
};

export default CartSideBar;
