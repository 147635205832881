import { useEffect, useState } from "react";
import { PersistGate } from "redux-persist/integration/react";

import { RestaurantOpeningHoursType } from "../../../api/types/restaurant.type";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectRestaurant,
  setRestaurantTime,
} from "../../../redux/slices/restaurantSlice";
import { persistor } from "../../../redux/store";
import {
  excludeClosingHoursOfRestaurant,
  filterOpeningHoursOfRestaurantFromInitialTime,
  initiaTimeOptions,
} from "../../../utils/location";
import classes from "./time.module.scss";
import { TimeSelectionType } from "./types/timeSelection.type";

interface Props {
  time: TimeSelectionType;
  isOpen: boolean;
  closingHours: string[];
  // setOffHoliday: Dispatch<SetStateAction<boolean>>
}

const TimeOptions = ({ time, isOpen, closingHours }: Props) => {
  const dispatch = useAppDispatch();

  const restaurant = useAppSelector(selectRestaurant);

  const [timeOptions, setTimeOptions] = useState(initiaTimeOptions);

  const handleSelectTime = (time: string) => {
    dispatch(setRestaurantTime(time));
  };

  const handleDynamicTime = (option: string) => {
    const date = new Date();
    const newDate = new Date(date.getTime() + 15 * 60000);
    const time = newDate.getHours() * 100 + newDate.getMinutes();
    const optionTime = +option.substring(0, 2) * 100 + +option.substring(3, 5);

    if (time <= optionTime) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const currentdDay = new Date().getDay();
    const times: RestaurantOpeningHoursType[] = [];
    const day = currentdDay === 0 ? 7 : currentdDay;
    const nextDay = day === 7 ? 1 : day + 1;
    restaurant.openingHours.forEach((item) => {
      if (
        (item.weekday === day && restaurant.date === "dynamic") ||
        (item.weekday === nextDay && restaurant.date === "static")
      )
        times.push(item);
    });
    let tmp: string[] = [];
    if (times.length !== 0) {
      times.forEach((item) => {
        const tmp1 = filterOpeningHoursOfRestaurantFromInitialTime(
          initiaTimeOptions,
          item
        );
        const tmp2 = excludeClosingHoursOfRestaurant(tmp1, closingHours);
        tmp = [...tmp, ...tmp2];
      });

      if (tmp.length) setTimeOptions([...tmp]);
    }
  }, [restaurant.date, restaurant.openingHours]);

  useEffect(() => {
    if (restaurant.date === "dynamic") {
      timeOptions.forEach((option) => {
        const date = new Date();
        const newDate = new Date(date.getTime() + 15 * 60000);
        const time = newDate.getHours() * 100 + newDate.getMinutes();
        const optionTime =
          +option.substring(0, 2) * 100 + +option.substring(3, 5);
        if (time >= optionTime) {
          // ref?.current?.getElementById(option).scrollIntoView();
          // console.log(document.getElementById(option)?.parentElement?.querySelector(`[id="${option}"]`));
          // document.getElementById(option)?.parentElement?.querySelector(`[id="${option}"]`).scrollIntoView({
          //   block: 'nearest'
          // });
          const child = document.getElementById(option);
          const parent = child?.parentElement;
          child?.scrollIntoView({ block: "center", behavior: "smooth" });
          // if (parent)
          //   parent.scrollTop += parent.getBoundingClientRect()?.height;
          return;
        }
      });
    }
  }, [timeOptions, isOpen, restaurant.date]);

  return (
    <PersistGate persistor={persistor} loading={<>Loading...</>}>
      <div className={classes.list}>
        {timeOptions.map((option, i) => (
          <p
            key={i}
            id={option}
            className={`${classes.item} ${
              handleDynamicTime(option) && time.timeSelection === "dynamic"
                ? classes.notAvailable
                : ""
            }`}
            onClick={() => {
              if (time.timeSelection === "dynamic") {
                if (!handleDynamicTime(option)) {
                  handleSelectTime(option);
                }
              } else {
                handleSelectTime(option);
              }
            }}
          >
            {option}
          </p>
        ))}
      </div>
    </PersistGate>
  );
};

export default TimeOptions;
