import { GiCheckMark } from "react-icons/gi";

import { useAppDispatch } from "../../../../redux/hooks";
import {
  addCustomFormulaToCart,
  CustomFormulaItem,
  removeAlcoholicBeverages,
  removeCustomFormulaFromCart,
} from "../../../../redux/slices/cartSlice";
import AddButton from "../../../ui/button/AddButton";
import SubtractButton from "../../../ui/button/SubtractButton";
import classes from "./customSaladItem.module.scss";

interface Props {
  item: CustomFormulaItem;
  id: number;
}

const CustomSaladItemCard = ({ item, id }: Props) => {
  const dispatch = useAppDispatch();

  const handleOnClick = (add: boolean) => {
    if (add) {
      dispatch(addCustomFormulaToCart({ id: id }));
    } else {
      dispatch(removeCustomFormulaFromCart({ id: id }));
      dispatch(removeAlcoholicBeverages());
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.details}>
          <h3>Formule Sur Mesure</h3>
          {item.recipeTitle && <h4>{item.recipeTitle}</h4>}
          <div className={classes.formulas}>
            {item.bases.length > 0 && (
              <p>
                <div className={classes.checkmark}>
                  <GiCheckMark />
                </div>
                <p>
                  Salade avec{" "}
                  {`${item.bases.map((base) => `${base.ingredient.title}, `)}
                    ${item.ingredients.map(
                      (ingredient) => ` ${ingredient.ingredient.title}`
                    )}
                    ${item.sauces.map((sauce) => `, ${sauce.ingredient.title}`)}
                  `}
                </p>
              </p>
            )}
            {item.sides1.length > 0 && (
              <p className={classes.item}>
                <div className={classes.checkmark}>
                  <GiCheckMark />
                </div>
                <p>{item.sides1.map((side) => ` ${side.ingredient.title}`)}</p>
              </p>
            )}
            {item.sides2.length > 0 && (
              <p className={classes.item}>
                <div className={classes.checkmark}>
                  <GiCheckMark />
                </div>
                <p>{item.sides2.map((side) => ` ${side.ingredient.title}`)}</p>
              </p>
            )}
          </div>
          <div className={classes.bottomContainer}>
            <div className={classes.add}>
              <SubtractButton onClick={() => handleOnClick(false)} />
              <div className={classes.quantity}>{item.quantity}</div>
              <AddButton onClick={() => handleOnClick(true)} />
            </div>
            <div className={classes.price}>
              <p className={classes.header}>Montant TTC</p>
              <p>{(item.formulaTotal * item.quantity).toFixed(2)} €</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomSaladItemCard;
