import { useMediaQuery } from "@mui/material";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import { SingleValue } from "react-select";

import { signOut } from "../../../../api/users/logout";
import {
  Locale,
  redirectToEn,
  redirectToEs,
  redirectToFr,
  redirectToNl,
} from "../../../../api/utils/helpers/langRedirection";
import { translationsContent } from "../../../../localization/translations";
import { useAppDispatch } from "../../../../redux/hooks";
import { clearCart } from "../../../../redux/slices/cartSlice";
import {
  resetRestaurantInfo,
  setRestaurantInfo,
} from "../../../../redux/slices/restaurantSlice";
import { useLanguage } from "../../../../store/lang.context";
import { useRestaurants } from "../../../../store/restaurants.context";
import { useUser } from "../../../../store/user.context";
import { removeQueryParam } from "../../../../utils/general";
import imageLoader from "../../../../utils/loader";
import { translate } from "../../../../utils/translate";
import Button from "../../../ui/button/SimpleButton";
import AccountDropdown from "../../../ui/dropdown/AccountDropdown";
import AccountDropdownMobile from "../../../ui/dropdown/AccountDropdownMobile";
import { OptionProps } from "../../../ui/dropdown/SimpleDropdown";
import CartSideBar from "../../CartSideBar";
import SideBar from "../../SideBar";
import HeaderItem from "./Item/index";
import classes from "./navBar.module.scss";

interface Props {
  setLoginIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMenuIsOpen: () => void;
}

const listLanguages: Locale[] = ["fr", "en", "es", "nl"];

const NavBar = ({ setLoginIsOpen, setMenuIsOpen }: Props) => {
  const isMobile = useMediaQuery("(max-width: 900px)");

  const { setUser } = useUser();
  const { language, setLanguage } = useLanguage();
  const { user } = useUser();
  const router = useRouter();
  const [sidebarOpen, setSideBarOpen] = useState(false);
  const [cartSidebarOpen, setCartSideBarOpen] = useState(false);

  const isHomepage =
    router.pathname === "/" ||
    router.pathname === "/en" ||
    router.pathname === "/es" ||
    router.pathname === "/nl";

  const isMenupage = router.pathname.includes("/carte");

  const dispatch = useAppDispatch();
  const { restaurants } = useRestaurants();

  const handleDropdownInputChange = (newValue: SingleValue<OptionProps>) => {
    switch (newValue?.value) {
      case "Sign Out":
        signOut();
        setUser(undefined);
        dispatch(clearCart());
        dispatch(resetRestaurantInfo());
        router.push("/");
        break;
      default:
        router.push(`${newValue?.value}`);
    }
  };

  const getCurrentRoute = () => {
    let currentRoute = router.route;
    if (router.query.restaurant || router.query.restaurantId) {
      currentRoute = `${router.route.substr(0, router.route.indexOf("["))}${
        router.query.restaurant
      }/${router.query.restaurantId}`;
    }
    if (router.query.orderId) {
      currentRoute = `${router.route.substr(0, router.route.indexOf("["))}${
        router.query.orderId
      }`;
    }
    if (router.query.slug) {
      currentRoute = `${router.route.substr(0, router.route.indexOf("["))}${
        router.query.slug
      }`;
    }
    return currentRoute;
  };

  const checkIfChangeInRouteIsNeeded = (currentRoute: string) => {
    return (
      !currentRoute.includes("carte") &&
      !currentRoute.includes("payment") &&
      !currentRoute.includes("confirmation") &&
      !currentRoute.includes("notre-restaurant")
    );
  };

  const handleSwitchToFR = () => {
    const currentRoute = getCurrentRoute();
    if (checkIfChangeInRouteIsNeeded(currentRoute)) {
      const newRoute = redirectToFr(currentRoute);
      router.push(newRoute);
    }
  };

  const handleSwitchToEN = () => {
    const currentRoute = getCurrentRoute();
    if (checkIfChangeInRouteIsNeeded(currentRoute)) {
      const newRoute = redirectToEn(currentRoute);
      router.push(newRoute);
    }
  };
  const handleSwitchToES = () => {
    const currentRoute = getCurrentRoute();
    if (checkIfChangeInRouteIsNeeded(currentRoute)) {
      const newRoute = redirectToEs(currentRoute);
      router.push(newRoute);
    }
  };
  const handleSwitchToNL = () => {
    const currentRoute = getCurrentRoute();
    if (checkIfChangeInRouteIsNeeded(currentRoute)) {
      const newRoute = redirectToNl(currentRoute);
      router.push(newRoute);
    }
  };

  const handleRouting = () => {
    switch (language) {
      case "fr":
        router.push("/");
        break;
      case "en":
        router.push("/en/");
        break;
      case "es":
        router.push("/es/");
        break;
      case "nl":
        router.push("/nl/");
        break;
    }
  };

  const sidebarHandler = () => {
    setSideBarOpen(!sidebarOpen);
  };

  const cartSidebarHandler = () => {
    setCartSideBarOpen(!cartSidebarOpen);
  };

  const handleOnClick = (id: Locale) => {
    if (localStorage.getItem("language") !== id) {
      localStorage.setItem("language", id);
      setLanguage(id);
      switch (localStorage.getItem("language")) {
        case "fr":
          handleSwitchToFR();
          break;
        case "en":
          handleSwitchToEN();
          break;
        case "es":
          handleSwitchToES();
          break;
        case "nl":
          handleSwitchToNL();
          break;
      }
    }
  };

  useEffect(() => {
    if (router.query.action === "openPopup") {
      setMenuIsOpen();
      if (router.query.restaurantId) {
        const restaurant = restaurants.find(
          (r) => r.id === +(router.query.restaurantId || 0)
        );
        if (restaurant)
          dispatch(
            setRestaurantInfo({
              id: restaurant.id,
              name: restaurant.name,
              address: restaurant.addresse || "",
              openingHours: restaurant.opening_hours,
              closingRules: restaurant.closing_rules,
            })
          );
        if (restaurants && restaurants.length)
          removeQueryParam(["action", "restaurantId"], router);
      } else {
        removeQueryParam(["action"], router);
      }
    } else if (router.query.action === "login") {
      setLoginIsOpen(true);
    }
  }, [router.query, restaurants]);

  return (
    <div>
      <div className={classes.upperContainer}>
        <div>
          <a href="/pdfs/allergenesajour.pdf" target="_blank" rel="noreferrer">
            <p>Liste des allergenes</p>
          </a>
          {listLanguages.map((lang, index) => {
            return (
              <>
                <p
                  className={`${classes.item} ${
                    language === lang && classes.selected
                  }`}
                  onClick={() => handleOnClick(lang)}
                >
                  {lang.toUpperCase()}
                </p>
                {index !== 3 && <span>|</span>}
              </>
            );
          })}
        </div>
      </div>
      <div className={classes.header}>
        <h1 onClick={handleRouting}>
          <Image
            layout="fixed"
            loader={imageLoader}
            width={"185px"}
            height={"40px"}
            src={"eat-salad-transparent.png"}
            alt="Eat Salad Logo"
          />
        </h1>
        <div className={classes.container}>
          {user?.id ? (
            <div className={classes.dropdown}>
              {isMobile ? (
                <div className={classes.accountDropdownMobile}>
                  <AccountDropdownMobile />
                  <p>
                    {user.first_name.slice(0, 1) + user.last_name.slice(0, 1)}
                  </p>
                </div>
              ) : (
                <AccountDropdown
                  user={user?.first_name + " " + user.last_name[0] + "."}
                  name="User"
                  placeholder={user?.first_name + " " + user.last_name[0] + "."}
                  onChange={handleDropdownInputChange}
                />
              )}
            </div>
          ) : (
            <HeaderItem
              src=""
              size={24}
              onClick={() => setLoginIsOpen(true)}
              headerNavName="headerNav1"
              isIconComponent
            >
              <BsPerson className={classes.personsIcon} />
            </HeaderItem>
          )}
          {!isHomepage && (
            <HeaderItem
              src=""
              size={24}
              onClick={() => setCartSideBarOpen(true)}
              headerNavName="headerNav2"
              isIconComponent
            >
              <AiOutlineShoppingCart />
            </HeaderItem>
          )}
          {!isMobile && !isMenupage && (
            <div className={classes.orderButtonContainer}>
              <Button
                label={`${translate(
                  translationsContent,
                  "orderNow",
                  language
                )}`}
                onClick={() => {
                  setMenuIsOpen();
                }}
              />
            </div>
          )}
          <div className={classes.menu} onClick={sidebarHandler}>
            <span />
            <span />
            <span style={{ width: "21px" }} />
          </div>
        </div>
        <SideBar
          open={sidebarOpen}
          setSideBarOpen={sidebarHandler}
          setMenuIsOpen={setMenuIsOpen}
        />
        <CartSideBar
          open={cartSidebarOpen}
          setSideBarOpen={cartSidebarHandler}
        />
      </div>
    </div>
  );
};

export default NavBar;
