import Image from "next/image";
import router from "next/router";
import React, { ReactElement, useState } from "react";
import { MdOutlineStyle, MdPerson, MdStar } from "react-icons/md";
import Select, {
  ActionMeta,
  components,
  SingleValue,
  StylesConfig,
} from "react-select";

import { translationsContent } from "../../../../localization/translations";
import { useLanguage } from "../../../../store/lang.context";
import imageLoader from "../../../../utils/loader";
import { translate } from "../../../../utils/translate";
import SaladLogo from "../../svgs/SaladLogo";
import classes from "./accountDropdown.module.scss";

export interface OptionProps {
  value: string | number;
  label: string | number;
  children: ReactElement;
  header?: boolean;
  selected?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  onClick?: () => void;
}

interface Props {
  customStyles?: () => StylesConfig<OptionProps>;
  user: string;
  name: string;
  label?: string;
  error?: boolean;
  isValid?: boolean;
  placeholder?: string;
  onChange: (
    newValue: SingleValue<OptionProps>,
    actionMeta: ActionMeta<OptionProps>
  ) => void;
}

const SaladIcon = ({ hoverable = true }: { hoverable?: boolean }) => {
  return (
    <>
      <div
        className={`${hoverable ? classes.notActive : classes.alwaysNotActive}`}
      >
        <Image
          loader={imageLoader}
          src={"salad.svg"}
          alt={"Salad Icon"}
          width={20}
          height={20}
        />
      </div>
      {hoverable && (
        <div className={classes.active}>
          <Image
            loader={imageLoader}
            src={"salad-green.svg"}
            alt={"Selected Salad Icon"}
            width={20}
            height={20}
          />
        </div>
      )}
    </>
  );
};

const Option = ({
  children,
  header = false,
  label,
  selected,
}: React.PropsWithChildren<OptionProps>) => {
  return (
    <a className={classes.link}>
      <div className={`${classes.button} ${selected ? classes.selected : ""}`}>
        <div className={classes.icon}>{children}</div>
        <div className={`${classes.label} ${header ? classes.header : ""}`}>
          {label}
        </div>
      </div>
    </a>
  );
};

const AccountDropdown = ({
  user,
  name,
  label,
  onChange,
  placeholder,
  customStyles,
}: Props) => {
  const [open, setOpen] = useState(false);

  const { language } = useLanguage();

  const styles = (): StylesConfig<OptionProps> => ({
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
      backgroundColor: `${
        name.includes("User")
          ? "var(--primary-background-color)"
          : "var(--alternate-background-color)"
      }`,
      color: "var(--primary-text-color) !important",
      border: "1px solid var(--alternate-background-color)",
      borderRadius: "25px",
      textAlign: `${name.includes("User") ? "center" : "start"}`,
      width: "204px",
      maxWidth: "204px",
      height: "42px",
      paddingLeft: "0",
      boxShadow: "none",
      "&:focus": {
        border: "none",
      },
      "&:after": {
        display: "none",
      },
      "&:hover": {
        color: "var(--primary-text-color) !important",
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      marginLeft: 0,
      paddingLeft: 0,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: `${
        name.includes("User")
          ? "var(--primary-text-color) !important"
          : "var(--secondary-text-color)"
      }`,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "var(--primary-text-color) !important",
    }),
    dropdownIndicator: (styles, state) => ({
      ...styles,
      color: `${
        name.includes("User")
          ? "var(--primary-text-color) !important"
          : "var(--secondary-text-color)"
      }`,
      transition: "all 200ms ease",
      padding: "0",
      marginRight: "20px",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
    }),
    indicatorSeparator: () => ({ display: "none" }),
    menu: (styles) => ({
      ...styles,
      position: "absolute",
      right: "-52px",
      left: "",
      backgroundColor: "var(--tertiary-background-color)",
      margin: "10px 0 0",
      padding: "0 !important",
      width: "320px",
      height: "310px",
      transition: "all 50ms ease-in-out",
      overflow: "none",
      borderRadius: "25px",
    }),
    menuList: () => ({
      paddingTop: 0,
      paddingBottom: 0,
      height: "100% important!",
      transition: "all 50ms ease-in-out",
      overflow: "none",
    }),
    option: (styles) => ({
      ...styles,
      height: "63px",
      borderRadius: "25px",
      padding: "0 !important",
      backgroundColor: "var(--tertiary-background-color)",
      textAlign: "start",
      transition: "background-color 200ms linear",
      overflow: "none",
      "&:hover": {
        background: "var(--alternate-background-color)",
        cursor: "pointer",
      },
    }),
  });

  const defaultOption: OptionProps = {
    label: user,
    value: "",
    header: true,
    children: <MdPerson className={classes.icon1} />,
  };
  const options: OptionProps[] = [
    {
      label: `${translate(
        translationsContent,
        "accountHeader1Label",
        language
      )}`,
      value: `${translate(translationsContent, "accountHeader1", language)}`,
      selected:
        router.pathname ==
        `${translate(translationsContent, "accountHeader1", language)}`,
      children: <MdPerson className={classes.icon} />,
    },
    {
      label: `${translate(
        translationsContent,
        "accountHeader2Label",
        language
      )}`,
      value: `${translate(translationsContent, "accountHeader2", language)}`,
      selected:
        router.pathname ==
        `${translate(translationsContent, "accountHeader2", language)}`,
      children: <MdOutlineStyle className={classes.icon} />,
    },
    {
      label: `${translate(
        translationsContent,
        "accountHeader4Label",
        language
      )}`,
      value: `${translate(translationsContent, "accountHeader4", language)}`,
      selected:
        router.pathname ==
        `${translate(translationsContent, "accountHeader4", language)}`,
      children: <MdStar className={classes.icon} />,
    },
    {
      label: `${translate(
        translationsContent,
        "accountHeader5Label",
        language
      )}`,
      value: `${translate(translationsContent, "accountHeader5", language)}`,
      selected:
        router.pathname ==
        `${translate(translationsContent, "accountHeader5", language)}`,
      children: <SaladLogo className={classes.icon2} />,
    },
    {
      label: `${translate(translationsContent, "disconnect", language)}`,
      value: "Sign Out",
      children: <></>,
    },
  ];

  return (
    <>
      {label && <p>{label}</p>}
      <div onClick={() => setOpen(!open)}>
        <Select<OptionProps>
          value={defaultOption}
          onChange={onChange}
          options={options}
          formatOptionLabel={Option}
          isSearchable={false}
          onBlur={() => setOpen(false)}
          styles={customStyles ? customStyles() : styles()}
          name={name}
          placeholder={placeholder}
          minMenuHeight={500}
          components={{
            Menu: (props) => (
              <components.Menu {...props} className={classes.menu} />
            ),
            Option: (props) => (
              <components.Option {...props} className={classes.option} />
            ),
            DropdownIndicator: (props) => (
              <components.DropdownIndicator
                {...props}
                className={classes.arrow}
              />
            ),
          }}
        />
      </div>
    </>
  );
};

export default AccountDropdown;
