import { GiCheckMark } from "react-icons/gi";

import { cartContainsItem } from "../../../../api/utils/helpers/cartContainsItem";
import { roundPrice } from "../../../../api/utils/helpers/roundPrice";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  addFormulaToCart,
  FormulaItem,
  removeAlcoholicBeverages,
  removeFormulaFromCart,
} from "../../../../redux/slices/cartSlice";
import { useLanguage } from "../../../../store/lang.context";
import { returnIndex } from "../../../../utils/returnIndex";
import AddButton from "../../../ui/button/AddButton";
import SubtractButton from "../../../ui/button/SubtractButton";
import classes from "./formulaItem.module.scss";

interface Props {
  formula: FormulaItem;
  id: number;
  isEatfid?: boolean;
}

const FormulaItemCard = ({ formula, id, isEatfid = false }: Props) => {
  const dispatch = useAppDispatch();
  const { language } = useLanguage();

  const handleOnClick = (add: boolean) => {
    if (add) {
      dispatch(addFormulaToCart({ formula: formula.formula, id: id }));
    } else {
      dispatch(removeFormulaFromCart({ formula: formula.formula, id: id }));
      dispatch(removeAlcoholicBeverages());
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.details}>
          <h3>{formula.formula.title}</h3>
          {formula.recipeTitle && <h4>{formula.recipeTitle}</h4>}
          <div className={classes.formulas}>
            {formula.bases.length > 0 && (
              <p>
                <div className={classes.checkmark}>
                  <GiCheckMark />
                </div>
                <p>
                  Salade avec{" "}
                  {`${formula.bases.map((base) => `${base.ingredient.title}, `)}
                  ${
                    formula.extraBases.length > 0
                      ? formula.extraBases.map((base) => {
                          if (
                            cartContainsItem(base.ingredient, formula.bases) ===
                            -1
                          )
                            return `${base.ingredient.title}, `;
                        })
                      : ""
                  }
                  ${
                    formula.ingredients.length > 0
                      ? formula.ingredients.map(
                          (ingredient) => ` ${ingredient.ingredient.title}`
                        )
                      : ""
                  }
                  ${
                    formula.extraIngredients.length > 0
                      ? formula.extraIngredients.map((ingredient) => {
                          if (
                            cartContainsItem(
                              ingredient.ingredient,
                              formula.ingredients
                            ) === -1
                          )
                            return ` ${ingredient.ingredient.title}`;
                        })
                      : ""
                  }
                  ${
                    formula.sauces.length > 0
                      ? formula.sauces.map(
                          (sauce) => `, ${sauce.ingredient.title}`
                        )
                      : ""
                  }
                  ${
                    formula.extraSauces
                      ? formula.extraSauces.map((sauce) => {
                          if (
                            cartContainsItem(
                              sauce.ingredient,
                              formula.sauces
                            ) === -1
                          )
                            return `, ${sauce.ingredient.title}`;
                        })
                      : ""
                  }
                `}
                </p>
              </p>
            )}
            {formula.sides1.length > 0 && (
              <p className={classes.item}>
                <div className={classes.checkmark}>
                  <GiCheckMark />
                </div>
                <p>
                  {formula.sides1.map((side) => ` ${side.ingredient.title}`)}
                  {`${
                    formula.extraSides1.length > 0
                      ? formula.extraSides1.map((side) => {
                          if (
                            cartContainsItem(
                              side.ingredient,
                              formula.sides1
                            ) === -1
                          )
                            return ` ${side.ingredient.title}`;
                        })
                      : ""
                  }`}
                </p>
              </p>
            )}
            {formula.sides2.length > 0 && (
              <p className={classes.item}>
                <div className={classes.checkmark}>
                  <GiCheckMark />
                </div>
                <p>
                  {formula.sides2.map((side) => ` ${side.ingredient.title}`)}
                  {`${
                    formula.extraSides2.length > 0 &&
                    formula.extraSides2.map((side) => {
                      if (
                        cartContainsItem(side.ingredient, formula.sides2) === -1
                      )
                        return ` ${side.ingredient.title}`;
                    })
                  }`}
                </p>
              </p>
            )}
            {formula.desserts.length > 0 && (
              <p className={classes.item}>
                <div className={classes.checkmark}>
                  <GiCheckMark />
                </div>
                <p>
                  {formula.desserts.map(
                    (dessert) =>
                      `${
                        dessert.dessert[returnIndex(language)]
                      }${dessert.toppings.map(
                        (topping) =>
                          `, ${topping.ingredient[returnIndex(language)]}`
                      )}${dessert.coulis.map(
                        (couli) =>
                          `, ${couli.ingredient[returnIndex(language)]}`
                      )}
                      `
                  )}
                </p>
              </p>
            )}
          </div>
          <div className={classes.bottomContainer}>
            <div className={classes.add}>
              <SubtractButton onClick={() => handleOnClick(false)} />
              <div className={classes.quantity}>{formula.quantity}</div>
              {!isEatfid && <AddButton onClick={() => handleOnClick(true)} />}
            </div>
            <div className={classes.price}>
              <p className={classes.header}>Montant TTC</p>
              <p>
                {roundPrice(formula.formulaTotal * formula.quantity).toFixed(2)}
                €
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormulaItemCard;
