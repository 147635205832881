import { useMediaQuery } from "@mui/material";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

import {
  Locale,
  redirectToEn,
  redirectToEs,
  redirectToFr,
  redirectToNl,
} from "../../../api/utils/helpers/langRedirection";
import { translationsContent } from "../../../localization/translations";
import { useLanguage } from "../../../store/lang.context";
import imageLoader from "../../../utils/loader";
import { translate } from "../../../utils/translate";
import Button from "../../ui/button/SimpleButton";
import classes from "./sideBar.module.scss";

interface Props {
  open: boolean;
  setSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMenuIsOpen: () => void;
}

type TranslationHeader =
  | "homepageHeader"
  | "conceptHeader"
  | "franchiseHeader"
  | "fidelityHeader"
  | "restaurantsHeader"
  | "productsHeader"
  | "newsHeader"
  | "recruitementHeader";

const listSet1 = [
  { route: "home", translateHeader: "homepageHeader" },
  { route: "concept", translateHeader: "conceptHeader" },
  { route: "franchise", translateHeader: "franchiseHeader" },
  { route: "fidelity", translateHeader: "fidelityHeader" },
  { route: "restaurants", translateHeader: "restaurantsHeader" },
  { route: "products", translateHeader: "productsHeader" },
  { route: "news", translateHeader: "newsHeader" },
];

const listSet2 = [
  {
    route: "recruitement",
    translateHeader: "recruitementHeader",
    newTab: "https://recrutement.eatsalad.com/",
  },
];

const listLanguages: Locale[] = ["fr", "en", "es", "nl"];

const socialLinks = [
  {
    href: "https://www.facebook.com/eatsalad/",
    src: "EATSALAD_social_facebook_white.svg",
    alt: "facebook",
  },
  {
    href: "https://www.instagram.com/eatsaladfr/",
    src: "EATSALAD_social_instagram_white.svg",
    alt: "instagram",
  },
  {
    href: "https://www.linkedin.com/company/eatsalad/?originalSubdomain=fr",
    src: "EATSALAD_social_linkedin_white.svg",
    alt: "linkedin",
  },
  {
    href: "https://www.tiktok.com/@eatsaladfrance?lang=fr",
    src: "EATSALAD_social_tiktok_white.svg",
    alt: "tiktok",
  },
];

const SideBar = ({ open, setSideBarOpen, setMenuIsOpen }: Props) => {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const { language, setLanguage } = useLanguage();

  const router = useRouter();

  const getCurrentRoute = () => {
    let currentRoute = router.route;
    if (router.query.restaurant || router.query.restaurantId) {
      currentRoute = `${router.route.substr(0, router.route.indexOf("["))}${
        router.query.restaurant
      }/${router.query.restaurantId}`;
    }
    if (router.query.orderId) {
      currentRoute = `${router.route.substr(0, router.route.indexOf("["))}${
        router.query.orderId
      }`;
    }
    if (router.query.slug) {
      currentRoute = `${router.route.substr(0, router.route.indexOf("["))}${
        router.query.slug
      }`;
    }
    return currentRoute;
  };

  const checkIfChangeInRouteIsNeeded = (currentRoute: string) => {
    return (
      !currentRoute.includes("carte") &&
      !currentRoute.includes("payment") &&
      !currentRoute.includes("confirmation") &&
      !currentRoute.includes("notre-restaurant")
    );
  };

  const handleSwitchToFR = () => {
    const currentRoute = getCurrentRoute();
    if (checkIfChangeInRouteIsNeeded(currentRoute)) {
      const newRoute = redirectToFr(currentRoute);
      router.push(newRoute);
    }
  };

  const handleSwitchToEN = () => {
    const currentRoute = getCurrentRoute();
    if (checkIfChangeInRouteIsNeeded(currentRoute)) {
      const newRoute = redirectToEn(currentRoute);
      router.push(newRoute);
    }
  };
  const handleSwitchToES = () => {
    const currentRoute = getCurrentRoute();
    if (checkIfChangeInRouteIsNeeded(currentRoute)) {
      const newRoute = redirectToEs(currentRoute);
      router.push(newRoute);
    }
  };
  const handleSwitchToNL = () => {
    const currentRoute = getCurrentRoute();
    if (checkIfChangeInRouteIsNeeded(currentRoute)) {
      const newRoute = redirectToNl(currentRoute);
      router.push(newRoute);
    }
  };

  const handleOnClick = (id: Locale) => {
    if (localStorage.getItem("language") !== id) {
      localStorage.setItem("language", id);
      setLanguage(id);
      switch (localStorage.getItem("language")) {
        case "fr":
          handleSwitchToFR();
          break;
        case "en":
          handleSwitchToEN();
          break;
        case "es":
          handleSwitchToES();
          break;
        case "nl":
          handleSwitchToNL();
          break;
      }
    }
    setSideBarOpen(false);
  };

  const getNewRoute = (path: string) => {
    let route = "";
    switch (path) {
      case "home":
        route = language !== "fr" ? "/" + language + "/" : "/";
        break;
      case "concept":
        route = "https://eatsalad.com/repas-healthy-valeurs/";
        break;
      case "franchise":
        route = "https://eatsalad.com/ouvrir-franchise-salade/";
        break;
      case "fidelity":
        route = "https://eatsalad.com/fidelite/";
        break;
      case "restaurants":
        switch (language) {
          case "fr":
            route = "/nos-restaurants/";
            break;
          case "en":
            route = "/en/our-locations/";
            break;
          case "es":
            route = "/es/nuestros-restaurantes/";
            break;
          case "nl":
            route = "/nl/onze-restaurants/";
            break;
        }
        break;
      case "products":
        route = "https://eatsalad.com/produits/";
        break;
      case "news":
        route = "https://eatsalad.com/actualites/";
        break;
      case "contact":
        if (language === "es") route = "/es/contacto/";
        else if (language === "fr") route = "/contact/";
        else route = "/" + language + "/contact/";
        break;
    }
    return route;
  };

  const getSelected = (id: string) => {
    switch (id) {
      case "home":
        if (
          router.route === "/" ||
          router.route === "/en" ||
          router.route === "/es" ||
          router.route === "/nl"
        )
          return true;
        break;
      case "restaurants":
        if (
          router.route.includes("nos-restaurants") ||
          router.route.includes("our-locations") ||
          router.route.includes("nuestros-restaurantes") ||
          router.route.includes("onze-restaurants")
        )
          return true;
        break;
      case "contact":
        if (
          router.route.includes("contact") ||
          router.route.includes("contacto")
        )
          return true;
        break;
      case "fidelity":
        if (
          router.pathname ===
            `${language === "fr" ? "" : `/${language}`}/la-fidelite` ||
          router.pathname === "/en/the-fidelity" ||
          router.pathname === "/nl/de-fidelite"
        )
          return true;
        break;
    }
  };

  return (
    <>
      <div className={!open ? classes.sidebarOpen : classes.sidebarClose}>
        <div className={classes.cancel}>
          <Image
            layout="fixed"
            loader={imageLoader}
            width={"30px"}
            height={"30px"}
            src={"cancel.svg"}
            alt="cancel"
            onClick={() => {
              setSideBarOpen(false);
            }}
          />
        </div>
        <div>
          <div style={{ marginBottom: "1rem" }}>
            <Image
              layout="fixed"
              loader={imageLoader}
              width={"185px"}
              height={"40px"}
              src={"eat-salad-transparent.png"}
              alt="logo"
            />
          </div>
          <div className={classes.list}>
            <div className={classes.listSet1}>
              <ul>
                {listSet1 &&
                  listSet1.map((set, i) => {
                    return (
                      <li
                        key={`side-bar-set-1-link-${i}`}
                        className={`${
                          getSelected(set.route) ? classes.selected : ""
                        }`}
                      >
                        <Link href={`${getNewRoute(set.route)}`} passHref>
                          <a onClick={() => setSideBarOpen(false)}>
                            {translate(
                              translationsContent,
                              set.translateHeader as TranslationHeader,
                              language
                            )}
                          </a>
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className={classes.listSet2}>
              <ul>
                {listSet2 &&
                  listSet2.map((set, i) => {
                    return (
                      <li
                        key={`side-bar-set-2-link-${i}`}
                        className={`${
                          getSelected(set.route) ? classes.selected : ""
                        }`}
                      >
                        {set.newTab ? (
                          <a
                            href={`${set.newTab}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {translate(
                              translationsContent,
                              set.translateHeader as TranslationHeader,
                              language
                            )}
                          </a>
                        ) : (
                          <Link href={`${getNewRoute(set.route)}`}>
                            <a onClick={() => setSideBarOpen(false)}>
                              {translate(
                                translationsContent,
                                set.translateHeader as TranslationHeader,
                                language
                              )}
                            </a>
                          </Link>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div>
            <Link href={getNewRoute("contact")}>
              <a>
                <p
                  className={`${
                    getSelected("contact") ? classes.selected : ""
                  } ${classes.contact}`}
                  onClick={() => getNewRoute("contact")}
                >
                  {translate(translationsContent, "contactHeader4", language)}
                </p>
              </a>
            </Link>
            {isMobile && (
              <div style={{ width: "fit-content" }}>
                <Button
                  label={translate(translationsContent, "orderNow", language)}
                  onClick={() => {
                    setMenuIsOpen();
                    setSideBarOpen(false);
                  }}
                />
              </div>
            )}
            <div className={classes.links}>
              {socialLinks.map((link) => {
                return (
                  <div key={`social-link-${link.alt}`}>
                    <a href={link.href} target="_blank" rel="noreferrer">
                      <Image
                        layout="fixed"
                        loader={imageLoader}
                        className={classes.socialIcon}
                        width={"40px"}
                        height={"40px"}
                        src={link.src}
                        alt={link.alt}
                      />
                    </a>
                  </div>
                );
              })}
            </div>

            <div className={classes.langHeader}>
              <p>
                {translate(translationsContent, "languageHeader", language)}
              </p>
            </div>
            <div className={classes.bottomContainer}>
              {listLanguages.map((lang) => {
                return (
                  <div
                    className={`${classes.item} ${
                      language === lang && classes.selected
                    }`}
                    key={`sidebar-lang-${lang}`}
                  >
                    <p onClick={() => handleOnClick(lang)}>
                      {lang.toUpperCase()}
                    </p>
                    <div className={classes.circle} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
