import { FunctionComponent } from "react";

import { Ingredient } from "../../../../api/types/restaurant.type";
import { translationsContent } from "../../../../localization/translations";
import { useLanguage } from "../../../../store/lang.context";
import { translate } from "../../../../utils/translate";
import AddButton from "../../../ui/button/AddButton";
import SubtractButton from "../../../ui/button/SubtractButton";
import classes from "./signatureItem.module.scss";

interface Props {
  title: string;
  qty: number;
  price: number;
  freeSauce?: Ingredient;
  onIncrement: () => void;
  onDecrement: () => void;
}

const SignatureItem: FunctionComponent<Props> = ({
  title,
  qty,
  price,
  freeSauce,
  onIncrement,
  onDecrement,
}: Props) => {
  const { language } = useLanguage();
  return (
    <div className={classes.cartItem}>
      <div className={classes.title}>{title}</div>
      <div className={classes.details}>
        <div className={classes.counter}>
          <SubtractButton onClick={onDecrement} />
          <div className={classes.qty}>{qty}</div>
          <AddButton onClick={onIncrement} />
        </div>
        <div className={classes.price}>{price.toFixed(2)} &euro;</div>
      </div>
      {freeSauce && (
        <>
          <div className={classes.freeSauceTitle}>
            {translate(translationsContent, "freeSauceCardContent", language)}:{" "}
            <b>{freeSauce.title}</b>
          </div>
        </>
      )}
    </div>
  );
};

export default SignatureItem;
