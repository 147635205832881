import { getCookie, hasCookie, setCookies } from "cookies-next";

export const setUserCookies = (accept: boolean) => {
  setCookies("userCookies", accept);
  setCookies("adsCookie", accept);
  setCookies("performanceCookie", accept);
  setCookies("personalizationCookie", accept);
};

export const getUserCookies = () => {
  return (
    hasCookie("userCookies") ||
    (hasCookie("adsCookie") &&
      hasCookie("performanceCookie") &&
      hasCookie("personalizationCookie"))
  );
};

export const setUserAdsCookies = (accept: string) => {
  setCookies("adsCookie", accept);
};

export const getUserAdsCookies = () => {
  return (getCookie("adsCookie") as string) ?? undefined;
};

export const setUserPerformanceCookies = (accept: string) => {
  setCookies("performanceCookie", accept);
};

export const getUserPerformanceCookies = () => {
  return (getCookie("performanceCookie") as string) ?? undefined;
};

export const setUserPersonalizationCookies = (accept: string) => {
  setCookies("personalizationCookie", accept);
};

export const getUserPersonalizationCookies = () => {
  return (getCookie("personalizationCookie") as string) ?? undefined;
};
