import { GiCheckMark } from "react-icons/gi";

import { roundPrice } from "../../../../api/utils/helpers/roundPrice";
import { translationsContent } from "../../../../localization/translations";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  addDessertToCart,
  deleteDessertFromCart,
  DessertItem,
} from "../../../../redux/slices/cartSlice";
import { useLanguage } from "../../../../store/lang.context";
import { translate } from "../../../../utils/translate";
import AddButton from "../../../ui/button/AddButton";
import SubtractButton from "../../../ui/button/SubtractButton";
import classes from "./dessertItem.module.scss";

interface Props {
  dessert: DessertItem;
  id: number;
  isEatfid?: boolean;
}

const DessertItemCard = ({ dessert, id, isEatfid = false }: Props) => {
  const dispatch = useAppDispatch();
  const { language } = useLanguage();

  const handleOnClick = (add: boolean) => {
    if (add) {
      dispatch(addDessertToCart({ id }));
    } else {
      dispatch(deleteDessertFromCart(id));
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.details}>
          <h3>{"Dessert sur mesure"}</h3>
          <div className={classes.formulas}>
            {dessert.toppings.length > 0 && (
              <p>
                <div className={classes.checkmark}>
                  <GiCheckMark />
                </div>
                <p>
                  {translate(translationsContent, "dessertContent", language)}{" "}
                  {`${dessert.toppings.map(
                    (topping, i) =>
                      `${i > 0 ? ", " : ""}${topping.ingredient.title}`
                  )}
                `}
                  {dessert.extraToppings.map(
                    (topping) => `, ${topping.ingredient.title}`
                  )}
                </p>
              </p>
            )}
            {dessert.coulis.length > 0 && (
              <p className={classes.item}>
                <div className={classes.checkmark}>
                  <GiCheckMark />
                </div>
                <p>
                  {dessert.coulis.map(
                    (couli, i) =>
                      `${i > 0 ? ", " : ""}${couli.ingredient.title}`
                  )}
                  {dessert.extraCoulis.map(
                    (couli) => `, ${couli.ingredient.title}`
                  )}
                </p>
              </p>
            )}
          </div>
          <div className={classes.bottomContainer}>
            <div className={classes.add}>
              <SubtractButton onClick={() => handleOnClick(false)} />
              <div className={classes.quantity}>{dessert.quantity}</div>
              {!isEatfid && <AddButton onClick={() => handleOnClick(true)} />}
            </div>
            <div className={classes.price}>
              <p className={classes.header}>Montant TTC</p>
              <p>
                {roundPrice(dessert.formulaTotal * dessert.quantity).toFixed(2)}
                €
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DessertItemCard;
