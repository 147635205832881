import { Locale } from "../api/utils/helpers/langRedirection";

export const returnIndex = (lang: Locale) => {
  switch (lang) {
    case "fr":
      return "title_fr";
    case "en":
      return "title_en";
    case "es":
      return "title_es";
    case "nl":
      return "title_nl";
  }
};
