import axios, { AxiosResponse } from "axios";

import { HereAddressApiResponse } from "../api/types/here.type";
import { HereAPIResponseType } from "../api/types/map.type";
import { getHereMapsAPIAutoCompleteUrl, getHereMapsAPILookupUrl } from "./maps";

export const getHerePredictions = async (input: string) => {
  try {
    const res: HereAddressApiResponse = await axios.get(
      getHereMapsAPIAutoCompleteUrl(input),
      {
        transformRequest: (data, headers) => {
          const common = headers?.common as unknown as {
            [key: string]: string;
          };
          delete common.Authorization;
          delete common.AccessToken;
          return data;
        },
      }
    );
    return res?.data?.items[0]?.id ?? "";
  } catch (e) {
    console.log(e);
    return "";
  }
};

export const getHereLocationData = async (placeID: string) => {
  const res = await axios.get<never, AxiosResponse<HereAPIResponseType>>(
    getHereMapsAPILookupUrl(placeID),
    {
      transformRequest: (data, headers) => {
        const common = headers?.common as unknown as {
          [key: string]: string;
        };
        delete common.Authorization;
        delete common.AccessToken;
        return data;
      },
    }
  );

  return { lat: res.data.position.lat, lng: res.data.position.lng };
};
