import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { NextSeo } from "next-seo";
import { useCallback, useEffect, useState } from "react";

import { SocialSignUpType } from "../../../api/types/social.type";
import {
  getUserCookies,
  getUserPerformanceCookies,
} from "../../../api/utils/userCookies/userCookies.util";
import { useAppSelector } from "../../../redux/hooks";
import { selectCart } from "../../../redux/slices/cartSlice";
import { useTakeawaySelector } from "../../../store/takeawaySelector.context";
import { useUser } from "../../../store/user.context";
import Takeaway from "../../Takeaway";
import classes from "./header.module.scss";
import NavBar from "./NavBar";

const Restaurants = dynamic(() => import("../../Menu/Restaurants"), {
  ssr: false,
});
const SocialSignUp = dynamic(() => import("../../auth/SocialSignup"), {
  ssr: false,
});
const Signup = dynamic(() => import("../../auth/Signup"), { ssr: false });
const Login = dynamic(() => import("../../auth/Login"), { ssr: false });
const UserCookiesPopup = dynamic(() => import("../../UserCookiesPopup"), {
  ssr: false,
});

interface Props {
  title?: string;
  description?: string;
  noindex?: boolean;
  nofollow?: boolean;
  picPath?: string;
  picAlt?: string;
  children: React.ReactNode;
}

const Header = ({
  children,
  title,
  description,
  picPath,
  picAlt,
  nofollow = true,
  noindex = true,
}: Props) => {
  const [loginIsOpen, setLoginIsOpen] = useState(false);
  const [signUpIsOpen, setSignUpIsOpen] = useState(false);
  const [socialSignUp, setSocialSignUp] = useState<SocialSignUpType>({
    id: 0,
    open: false,
  });
  const [cookiesPopupIsOpen, setCookiesPopupIsOpen] = useState(false);

  const router = useRouter();
  const { takeawaySelector, setTakeawaySelector } = useTakeawaySelector();
  const { user, setUser } = useUser();
  const cart = useAppSelector(selectCart);

  const googleTagManager = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-MF2SMGP');`;

  const canonicalUrl =
    process.env.NEXT_PUBLIC_SITE_URL +
    (router.asPath === "/" ? "" : router.asPath);
  const setAppVersion = useCallback(() => {
    const appVersion = localStorage.getItem("AppVersion");
    const persistKey = localStorage.getItem("PersistKey");
    if (appVersion !== process.env.NEXT_PUBLIC_APP_VERSION) {
      localStorage.removeItem(`persist:${persistKey ?? "root"}`);
      localStorage.setItem(
        "AppVersion",
        process.env.NEXT_PUBLIC_APP_VERSION ?? "no-version"
      );
      localStorage.setItem(
        "PersistKey",
        process.env.NEXT_PUBLIC_PERSIST_KEY ?? "root"
      );
    }
    setCookiesPopupIsOpen(!getUserCookies());
  }, []);

  const handleUserCookiesCloseModal = () => {
    setCookiesPopupIsOpen(false);
  };

  useEffect(() => {
    if (typeof window !== "undefined") setAppVersion();
  }, [setAppVersion]);

  useEffect(() => {
    if (user?.id) {
      setUser((prev) =>
        prev
          ? {
              ...prev,
              left_points: Math.max(
                +(prev.eatfid_points ?? 0) - (cart.eatfid_points_cost ?? 0),
                0
              ),
            }
          : prev
      );
    }
  }, [user?.id, cart.eatfid_points_cost, setUser]);

  useEffect(() => {
    const { reset } = router.query;
    if (reset === "true" && !user) {
      setLoginIsOpen(true);
    }
  }, [user, router]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=1"
        />
      </Head>
      <NextSeo
        title={title ?? "Eatsalad"}
        description={description}
        nofollow={nofollow}
        noindex={noindex}
        canonical={canonicalUrl}
        openGraph={{
          title,
          description,
          images: [
            {
              url: picPath ?? "/assets/ui/homepage1.jpg",
              alt: picAlt ?? "EatSalad",
              type: "image/jpg",
            },
          ],
        }}
      />
      <header id="top" className={classes.container}>
        <NavBar
          setLoginIsOpen={setLoginIsOpen}
          setMenuIsOpen={() => {
            setTakeawaySelector((prev) => ({
              ...prev,
              isTakeawayModalOpen: true,
            }));
          }}
        />
      </header>
      <main style={{ zIndex: "-100" }}>
        {/* <OutOfServiceStickyPopup /> */}
        {children}
        {loginIsOpen && (
          <Login
            setLoginIsOpen={setLoginIsOpen}
            setSignUpIsOpen={setSignUpIsOpen}
            setSocialSignUp={setSocialSignUp}
          />
        )}
        {signUpIsOpen && (
          <Signup
            setSignUpIsOpen={setSignUpIsOpen}
            setLoginIsOpen={setLoginIsOpen}
            setSocialSignUp={setSocialSignUp}
          />
        )}
        {socialSignUp.open && (
          <SocialSignUp
            setSignUpIsOpen={setSignUpIsOpen}
            setLoginIsOpen={setLoginIsOpen}
            socialSignUp={socialSignUp}
            setSocialSignUp={setSocialSignUp}
          />
        )}
        {takeawaySelector.isTakeawayModalOpen && <Takeaway />}
        {takeawaySelector.isRestaurantsModalOpen && (
          <Restaurants openTakeaway={true} />
        )}
        {cookiesPopupIsOpen && (
          <UserCookiesPopup onClose={handleUserCookiesCloseModal} />
        )}
      </main>
      {getUserPerformanceCookies() == "ACCEPTED" && (
        <Script
          async
          src="https://www.googletagmanager.com/ns.html?id=GTM-MF2SMGP"
        />
      )}
      {getUserPerformanceCookies() == "ACCEPTED" && (
        <Script
          id="G-RVT6H15HDG"
          async
          dangerouslySetInnerHTML={{ __html: googleTagManager }}
        />
      )}
    </>
  );
};

export default Header;
