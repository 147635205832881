import debounce from "lodash.debounce";
import moment from "moment";
import Image from "next/image";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";

import { getRestaurantById } from "../../api/restaurants/getRestaurantById";
import { RestaurantItemType } from "../../api/types/restaurant.type";
import { labelToAnchor } from "../../api/utils/helpers/labelToAnchor";
import { translationsContent } from "../../localization/translations";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  CustomFormulaItem,
  deleteItemFromCart,
  FormulaItem,
  Item,
  removeBaseFromCartCustomFormula,
  removeBaseFromCartFormula,
  removeIngredientFromCartCustomFormula,
  removeIngredientFromCartFormula,
  removeSauceFromCartCustomFromula,
  removeSauceFromCartFormula,
  removeSide1FromCartFormula,
  removeSide2FromCartFormula,
  selectCart,
} from "../../redux/slices/cartSlice";
import {
  selectRestaurant,
  setRestaurantDate,
  setRestaurantTime,
} from "../../redux/slices/restaurantSlice";
import { useLanguage } from "../../store/lang.context";
import { useLoader } from "../../store/loader.context";
import { useRestaurantChangePopup } from "../../store/restaurantChangePopup";
import { useRestaurants } from "../../store/restaurants.context";
import { useTakeawaySelector } from "../../store/takeawaySelector.context";
import { getHereLocationData, getHerePredictions } from "../../utils/here";
import imageLoader from "../../utils/loader";
import { translate } from "../../utils/translate";
import Button from "../ui/button/SimpleButton";
import DynamicModal from "../ui/modal/DynamicModal";
import Locations from "./Locations";
import classes from "./takeawayPage.module.scss";
import TimeOptions from "./TimeItem/item";
import { TimeSelectionType } from "./TimeItem/types/timeSelection.type";

export type FilteredLocationsType = {
  locations: RestaurantItemType[];
};

export type CartItemType = "ITEMS" | "FORMULA" | "CUSTOM";

export type FormulaItemType =
  | "BASE"
  | "E_BASE"
  | "ING"
  | "E_ING"
  | "SAUCE"
  | "E_SAUCE"
  | "SIDES1"
  | "E_SIDES1"
  | "SIDES2"
  | "E_SIDES2";

const Takeaway = () => {
  const restaurant = useAppSelector(selectRestaurant);

  const [openLocations, setOpenLocations] = useState(false);
  const [restaurantOptions, setRestaurantOptions] = useState({
    position: { lat: 0, lng: 0 },
    defaultRestaurants: true,
    localFilteredRestaurants: [] as RestaurantItemType[],
  });

  const [placeID, setPlaceID] = useState<string>();

  const [time, setTime] = useState<TimeSelectionType>({
    timeSelection: restaurant.date === "dynamic" ? "dynamic" : "static",
  });

  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [target, setTarget] = useState(true);
  const [notFoundIngredients, setNotFoundIngredients] = useState<string[]>([]);

  const { restaurants } = useRestaurants();
  const { setTakeawaySelector } = useTakeawaySelector();
  const dispatch = useAppDispatch();

  const cart = useAppSelector(selectCart);

  const { setLoader } = useLoader();
  const { setPopupIsOpen } = useRestaurantChangePopup();

  const router = useRouter();

  const { language } = useLanguage();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const outsideRef = useRef<HTMLDivElement>(null);

  const closingHours = restaurant.closingRules.filter(
    (rule) =>
      moment(rule.start).isSameOrBefore(
        moment(
          time.timeSelection === "static"
            ? new Date(Date.now()).valueOf() + 36e5 * 24
            : undefined
        ),
        "day"
      ) &&
      moment(rule.end).isSameOrAfter(
        moment(
          time.timeSelection === "static"
            ? new Date(Date.now()).valueOf() + 36e5 * 24
            : undefined
        ),
        "day"
      )
  );

  const closingHoursDisplay = closingHours.map((rule) => {
    const opening = restaurant.openingHours.find(
      (opening) =>
        moment(
          time.timeSelection === "static"
            ? new Date(Date.now()).valueOf() + 36e5 * 24
            : undefined
        ).day() === opening.weekday
    );

    const fromHour = opening ? opening.from_hour.split(":")[0] : "10";
    const fromMinute = opening ? opening.from_hour.split(":")[1] : "00";

    const toHour = opening ? opening.to_hour.split(":")[0] : "10";
    const toMinute = opening ? opening.to_hour.split(":")[1] : "00";

    const fromMoment = moment(
      time.timeSelection === "static"
        ? new Date(Date.now()).valueOf() + 36e5 * 24
        : undefined
    ).set({ hour: +fromHour, minute: +fromMinute });

    const toMoment = moment(
      time.timeSelection === "static"
        ? new Date(Date.now()).valueOf() + 36e5 * 24
        : undefined
    ).set({ hour: +toHour, minute: +toMinute });

    return (
      (moment(rule.start).isSameOrBefore(fromMoment)
        ? `${fromHour}:${fromMinute}`
        : moment(rule.start).format("HH:mm")) +
      " - " +
      (moment(rule.end).isSameOrAfter(toMoment)
        ? `${toHour}:${toMinute}`
        : moment(rule.end).format("HH:mm"))
    );
  });

  const isNotEligibleToDisplay = closingHours.some((closing) => {
    const opening = restaurant.openingHours.find(
      (opening) =>
        moment(
          time.timeSelection === "static"
            ? new Date(Date.now()).valueOf() + 36e5 * 24
            : undefined
        ).day() === opening.weekday
    );
    return (
      moment(closing.start).isSameOrBefore(
        moment(
          time.timeSelection === "static"
            ? new Date(Date.now()).valueOf() + 36e5 * 24
            : undefined
        ).set({
          hour: opening ? +opening.from_hour.split(":")[0] : 10,
          minute: opening ? +opening.from_hour.split(":")[1] : 0,
        })
      ) &&
      moment(closing.end).isSameOrAfter(
        moment(
          time.timeSelection === "static"
            ? new Date(Date.now()).valueOf() + 36e5 * 24
            : undefined
        ).set({
          hour: opening ? +opening.to_hour.split(":")[0] : 10,
          minute: opening ? +opening.to_hour.split(":")[1] : 0,
        })
      )
    );
  });

  const handleInputChange = (identifier: string, value: string) => {
    if (value === "dynamic" || value === "static") {
      dispatch(setRestaurantDate(value));
      setTime({ timeSelection: value });
    }
  };

  const handleLocalFilter = (value: string) => {
    const priorityOrder: ("name" | "addresse" | "city" | "both")[] = [
      "name",
      "addresse",
      "city",
      "both",
    ];

    let filteredRestaurants: RestaurantItemType[] = [];
    priorityOrder.forEach((key) => {
      filteredRestaurants = [
        ...filteredRestaurants,
        ...restaurants.filter((res) =>
          key !== "both"
            ? res[key]?.toLocaleLowerCase().includes(value.toLocaleLowerCase())
            : value.toLocaleLowerCase() ===
                `${res.city} ${res.addresse}`.toLocaleLowerCase() ||
              value.toLocaleLowerCase() ===
                `${res.city}, ${res.addresse}`.toLocaleLowerCase() ||
              value.toLocaleLowerCase() ===
                `${res.city},${res.addresse}`.toLocaleLowerCase() ||
              value.toLocaleLowerCase() ===
                `${res.addresse} ${res.city}`.toLocaleLowerCase() ||
              value.toLocaleLowerCase() ===
                `${res.addresse}, ${res.city}`.toLocaleLowerCase() ||
              value.toLocaleLowerCase() ===
                `${res.addresse},${res.city}`.toLocaleLowerCase()
        ),
      ];
    });

    return filteredRestaurants;
  };
  const handleSearch = async (value: string) => {
    console.log("triggerd");
    if (value.length === 0)
      setRestaurantOptions((prev) => ({ ...prev, defaultRestaurants: true }));
    else {
      const filteredRestaurants = handleLocalFilter(value);
      setRestaurantOptions((prev) => ({
        ...prev,
        defaultRestaurants: false,
        localFilteredRestaurants: filteredRestaurants,
      }));
      console.log(value, filteredRestaurants.length);
      if (value.length >= 4 && filteredRestaurants.length === 0) {
        const placeId = await getHerePredictions(value);
        console.log(placeId);
        setPlaceID(placeId);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearch = useCallback(
    debounce((value: string) => {
      handleSearch(value);
      setTarget(value.length === 0);
    }, 500),
    [restaurants]
  );

  const handleSearchOnFocus = () => {
    if (inputRef.current) inputRef.current.value = "";
    setOpenLocations(true);
  };

  const handleOnClose = () => {
    document.body.style.overflowY = "scroll";
    setTakeawaySelector((prev) => ({ ...prev, isTakeawayModalOpen: false }));
  };

  const handleSelectRestaurant = () => {
    router.push(`/carte/${labelToAnchor(restaurant.name)}/${restaurant.id}`);
  };

  const getName = (value: string) => {
    return value.substring(10);
  };

  const handleTime = () => {
    if (restaurant.date === "dynamic") {
      if (restaurant.time) {
        const date = new Date();
        const newDate = new Date(date.getTime() + 15 * 60000);
        const time = newDate.getHours() * 100 + newDate.getMinutes();
        const optionTime =
          +restaurant.time.substring(0, 2) * 100 +
          +restaurant.time.substring(3, 5);
        if (time >= optionTime) {
          dispatch(setRestaurantTime(""));
          return `${translate(
            translationsContent,
            "takeawayHeader5",
            language
          )}`;
        }
        return restaurant.time;
      } else {
        return `${translate(translationsContent, "takeawayHeader5", language)}`;
      }
    } else {
      if (isNotEligibleToDisplay) {
        dispatch(setRestaurantTime(""));
        return `${translate(translationsContent, "takeawayHeader5", language)}`;
      }
      if (restaurant.time) {
        return restaurant.time;
      }
      return `${translate(translationsContent, "takeawayHeader5", language)}`;
    }
  };

  const removeItem = (
    item: Item,
    ID: CartItemType,
    formulaItem?: FormulaItem,
    customFormula?: CustomFormulaItem
  ) => {
    const quantity = item.quantity;
    for (let i = 0; i < quantity; i++) {
      switch (ID) {
        case "ITEMS":
          dispatch(deleteItemFromCart(item.ingredient));
          break;
        case "FORMULA":
          if (formulaItem)
            switch (item.ingredient.category.slug) {
              case "base":
                dispatch(
                  removeBaseFromCartFormula({
                    formula: formulaItem.formula,
                    id: formulaItem.orderId,
                    ingredient: item.ingredient,
                  })
                );
                break;
              case "ingredient":
                dispatch(
                  removeIngredientFromCartFormula({
                    formula: formulaItem.formula,
                    id: formulaItem.orderId,
                    ingredient: item.ingredient,
                  })
                );
                break;
              case "sauces":
                dispatch(
                  removeSauceFromCartFormula({
                    formula: formulaItem.formula,
                    id: formulaItem.orderId,
                    ingredient: item.ingredient,
                  })
                );
                break;
              case "boissons" || "dessert":
                dispatch(
                  removeSide1FromCartFormula({
                    formula: formulaItem.formula,
                    id: formulaItem.orderId,
                    ingredient: item.ingredient,
                  })
                );
                dispatch(
                  removeSide2FromCartFormula({
                    formula: formulaItem.formula,
                    id: formulaItem.orderId,
                    ingredient: item.ingredient,
                  })
                );
                break;
            }
          break;
        case "CUSTOM":
          if (customFormula)
            switch (item.ingredient.category.slug) {
              case "base":
                dispatch(
                  removeBaseFromCartCustomFormula({
                    id: customFormula.orderId,
                    ingredient: item.ingredient,
                  })
                );
                break;
              case "ingredient":
                dispatch(
                  removeIngredientFromCartCustomFormula({
                    id: customFormula.orderId,
                    ingredient: item.ingredient,
                  })
                );
                break;
              case "sauces":
                dispatch(
                  removeSauceFromCartCustomFromula({
                    id: customFormula.orderId,
                    ingredient: item.ingredient,
                  })
                );
                break;
            }
          break;
      }
    }
  };

  const checkAvailability = (
    restaurant: RestaurantItemType,
    ingredients: Item[],
    ID: CartItemType,
    formulaItem?: FormulaItem,
    customFormula?: CustomFormulaItem
  ) => {
    let flag = true;
    let notFoundAtAll = true;
    ingredients.forEach((item) => {
      notFoundAtAll = true;
      restaurant.ingredients.forEach((item2) => {
        if (item.ingredient.id === item2.id) notFoundAtAll = false;
        if (
          item.ingredient.id === item2.id &&
          !item2.available_at_this_restaurant
        ) {
          if (flag) flag = false;
          if (notFoundIngredients.indexOf(item2.title) === -1) {
            setNotFoundIngredients((prevState) => [...prevState, item2.title]);
          }
          if (formulaItem) removeItem(item, ID, formulaItem);
          else if (customFormula)
            removeItem(item, ID, undefined, customFormula);
          else removeItem(item, ID);
        }
      });
      if (notFoundAtAll) {
        if (notFoundIngredients.indexOf(item.ingredient.title) === -1) {
          setNotFoundIngredients((prevState) => [
            ...prevState,
            item.ingredient.title,
          ]);
        }
        if (formulaItem) removeItem(item, ID, formulaItem);
        else if (customFormula) removeItem(item, ID, undefined, customFormula);
        else removeItem(item, ID);
        if (flag) flag = false;
      }
    });
    return flag;
  };

  const checkIngredients = async () => {
    setLoader(true);
    let flag = true;
    const response = await getRestaurantById(restaurant.id);
    if (response.success) {
      const restaurants = response.data;
      if (restaurants) {
        flag = checkAvailability(restaurants, cart.items, "ITEMS") && flag;
        cart.formulaItems.forEach((formula) => {
          flag =
            checkAvailability(
              restaurants,
              formula.extraBases,
              "FORMULA",
              formula
            ) && flag;
          flag =
            checkAvailability(restaurants, formula.bases, "FORMULA", formula) &&
            flag;
          flag =
            checkAvailability(
              restaurants,
              formula.extraIngredients,
              "FORMULA",
              formula
            ) && flag;
          flag =
            checkAvailability(
              restaurants,
              formula.ingredients,
              "FORMULA",
              formula
            ) && flag;
          flag =
            checkAvailability(
              restaurants,
              formula.extraSauces,
              "FORMULA",
              formula
            ) && flag;
          flag =
            checkAvailability(
              restaurants,
              formula.sauces,
              "FORMULA",
              formula
            ) && flag;
          flag =
            checkAvailability(
              restaurants,
              formula.extraSides1,
              "FORMULA",
              formula
            ) && flag;
          flag =
            checkAvailability(
              restaurants,
              formula.sides1,
              "FORMULA",
              formula
            ) && flag;
          if (formula.sides2) {
            flag =
              checkAvailability(
                restaurants,
                formula.extraSides2,
                "FORMULA",
                formula
              ) && flag;
            flag =
              checkAvailability(
                restaurants,
                formula.sides2,
                "FORMULA",
                formula
              ) && flag;
          }
        });
        cart.customFormulaItems.forEach((formula) => {
          flag =
            checkAvailability(
              restaurants,
              formula.bases,
              "CUSTOM",
              undefined,
              formula
            ) && flag;
          flag =
            checkAvailability(
              restaurants,
              formula.ingredients,
              "CUSTOM",
              undefined,
              formula
            ) && flag;
          flag =
            checkAvailability(
              restaurants,
              formula.extraSauces,
              "CUSTOM",
              undefined,
              formula
            ) && flag;
          flag =
            checkAvailability(
              restaurants,
              formula.sauces,
              "CUSTOM",
              undefined,
              formula
            ) && flag;
        });
      }
    }
    setLoader(false);
    return flag;
  };

  const handleCancelOnClick = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setOpenLocations(false);
      setRestaurantOptions((prev) => ({ ...prev, defaultRestaurants: true }));
      setTarget(true);
      setDisabled(true);
    }
  };

  const validateSelection = async () => {
    handleSelectRestaurant();
    await checkIngredients();
    setTakeawaySelector((prev) => ({
      ...prev,
      isTakeawayModalOpen: false,
    }));
    localStorage.setItem("path", "menu");
  };

  const getLocation = () => {
    setTarget(false);
    navigator.geolocation.getCurrentPosition(function (position) {
      setRestaurantOptions((prev) => ({
        ...prev,
        defaultRestaurants: false,
        localFilteredRestaurants: [],
        position: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
      }));
      setOpenLocations(true);
    });
  };

  const getLocationData = async (placeId: string) => {
    const { lat, lng } = await getHereLocationData(placeId);

    setRestaurantOptions((prev) => ({
      ...prev,
      defaultRestaurants: false,
      localFilteredRestaurants: [],
      position: { lat, lng },
    }));
    setOpenLocations(true);
  };

  useEffect(() => {
    if (placeID) getLocationData(placeID);
    return () => {};
  }, [placeID]);

  useEffect(() => {
    if (inputRef.current)
      if (restaurant.address !== "") {
        inputRef.current.value =
          restaurant.address + " " + getName(restaurant.name);
        setDisabled(false);
      }

    if (notFoundIngredients.length > 0) {
      setPopupIsOpen({
        isOpen: true,
        notAvailableIngredients: notFoundIngredients,
      });
    }
  }, [
    restaurant.address,
    restaurant.name,
    restaurant.id,
    notFoundIngredients,
    setPopupIsOpen,
  ]);

  useEffect(() => {
    if (inputRef.current && inputRef.current.value.length > 0) setTarget(false);
    else setTarget(true);
  }, [inputRef]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        outsideRef.current &&
        !outsideRef.current.contains(event.target as Node)
      )
        setOpenLocations(false);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [outsideRef]);

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = 0;
    }
  }, []);

  return (
    <div className={classes.modalContainer}>
      <DynamicModal onClose={handleOnClose}>
        <div className={classes.container} ref={divRef}>
          <h2>{translate(translationsContent, "orderNow", language)}</h2>
          <div className={classes.locationContainer}>
            <p>
              <span className={classes.step}>1 / 3</span>{" "}
              {translate(translationsContent, "takeawayHeader1", language)}
            </p>
            <div className={classes.inputContainer}>
              <div className={classes.search}>
                <div className={classes.logo}>
                  <Image
                    loader={imageLoader}
                    width="60px"
                    height="17px"
                    alt="map location"
                    src="eat-salad-transparent.png"
                  />
                </div>
                <textarea
                  placeholder={`${translate(
                    translationsContent,
                    "takeawayHeader2",
                    language
                  )}`}
                  onChange={(event) => {
                    debouncedHandleSearch(event.target.value);
                  }}
                  onFocus={handleSearchOnFocus}
                  ref={inputRef}
                  rows={1}
                />
                {inputRef.current?.value && inputRef.current?.value.length > 0 && (
                  <div className={classes.cancel} id="placePredictionsDiv">
                    <Image
                      loader={imageLoader}
                      layout="fixed"
                      width="15px"
                      height="15px"
                      src="cancel.svg"
                      alt="cancel"
                      onClick={handleCancelOnClick}
                    />
                  </div>
                )}
                {target && (
                  <div className={classes.rightLogo} onClick={getLocation}>
                    <Image
                      loader={imageLoader}
                      layout="fixed"
                      width="25px"
                      height="25px"
                      src="location.png"
                      alt="location"
                    />
                  </div>
                )}
                {!disabled && inputRef.current?.value !== "" && (
                  <div className={classes.locationFound}>
                    <Image
                      loader={imageLoader}
                      width="20px"
                      height="20px"
                      alt="map location"
                      src="foundLocation.svg"
                    />
                  </div>
                )}
                {openLocations && (
                  <Locations
                    outsideRef={outsideRef}
                    restaurants={restaurants}
                    restaurantOptions={restaurantOptions}
                    inputRef={inputRef}
                    setOpenLocations={setOpenLocations}
                    setDisabled={setDisabled}
                    setTarget={setTarget}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={classes.timeSelectContainer}>
            <div className={classes.space}>
              <p className={classes.header}>
                <span className={classes.step}>2 / 3</span>{" "}
                {translate(translationsContent, "takeawayHeader3", language)}
              </p>
              <div className={classes.radioButtons}>
                <div
                  className={`${classes.item} ${
                    (disabled || restaurant.date === "static") &&
                    classes.disabled
                  } ${
                    restaurant.date === "static" &&
                    !disabled &&
                    classes.notSelected
                  }`}
                >
                  <Button
                    label={`${translate(
                      translationsContent,
                      "confirmationContent4",
                      language
                    )}`}
                    onClick={() => {
                      handleInputChange("timeSelection", "dynamic");
                    }}
                    disabled={disabled}
                  />
                </div>
                <div
                  className={`${classes.item} ${
                    (disabled || restaurant.date === "dynamic") &&
                    classes.disabled
                  } ${
                    restaurant.date === "dynamic" &&
                    !disabled &&
                    classes.notSelected
                  }`}
                >
                  <Button
                    label={`${translate(
                      translationsContent,
                      "confirmationContent5",
                      language
                    )}`}
                    onClick={() => {
                      handleInputChange("timeSelection", "static");
                    }}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
            <div>
              <p>
                <span className={classes.step}>3 / 3</span>{" "}
                {translate(translationsContent, "takeawayHeader4", language)}
              </p>
              <div
                className={`${classes.dropdown} ${
                  (disabled || isNotEligibleToDisplay) && classes.timeDisabled
                }`}
                onClick={() => setOpen((prev) => !prev)}
              >
                <p className={classes.header}>
                  {handleTime()}
                  &nbsp;&nbsp;&nbsp;
                  <span
                    className={`${classes.caret} ${open && classes.caretUp}`}
                  />
                </p>

                {open && !disabled && !isNotEligibleToDisplay && (
                  <div className={classes.timeOptions}>
                    <TimeOptions
                      isOpen={open}
                      time={time}
                      closingHours={closingHoursDisplay}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={classes.button}>
              <Button
                label={`${translate(
                  translationsContent,
                  "validerButton",
                  language
                )}`}
                onClick={() => {
                  validateSelection();
                }}
                disabled={disabled || !restaurant.time}
              />
            </div>
          </div>
        </div>
      </DynamicModal>
    </div>
  );
};

export default Takeaway;
