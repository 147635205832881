import { FunctionComponent } from "react";

import AddButton from "../../../ui/button/AddButton";
import SubtractButton from "../../../ui/button/SubtractButton";
import classes from "./cartItem.module.scss";

interface Props {
  title: string;
  qty: number;
  price: number;
  isEatfid?: boolean;
  onIncrement: () => void;
  onDecrement: () => void;
}

const CartItem: FunctionComponent<Props> = ({
  title,
  qty,
  price,
  onIncrement,
  onDecrement,
  isEatfid = false,
}: Props) => {
  return (
    <div className={classes.cartItem}>
      <div className={classes.title}>{title}</div>
      <div className={classes.details}>
        <div className={classes.counter}>
          <SubtractButton onClick={onDecrement} />
          <div className={classes.qty}>{qty}</div>
          {!isEatfid && <AddButton onClick={onIncrement} />}
        </div>
        <div className={classes.price}>{price.toFixed(2)} &euro;</div>
      </div>
    </div>
  );
};

export default CartItem;
